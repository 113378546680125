<template>
 <div >
    <!--篩選區塊-->
    <el-row>
                                            <!--替換:過濾條件-->
      <!--
      <el-divider content-position="right"> {{$t("FilterConditions")}} </el-divider>-->

      <el-button
         style="float: left;"
         size="small"
         type="primary"
         icon="el-icon-tickets"
         v-if="(list!=undefined&&list!=null&&list.length>0)&&(listAll==undefined||listAll==null||listAll.length<=0)"
         @click="exportReport()"
       >{{$t('ExportReport')}}
      </el-button>

      <div style="float: left;">
      <!--匯出CSV按鈕:若回傳listall若無資料則匯出按鈕不顯示-->
      <download-excel  type="csv"
                       v-if="listAll!=undefined&&listAll!=null&&listAll.length>0">
           <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="exportToCSV" >.csv</el-button>
      </download-excel>         
      <!--
      <download-excel :data="listAll" :fields="json_fields" :name="filenameAll" type="csv"
                      v-if="listAll!=undefined&&listAll!=null&&listAll.length>0">
          <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="logOperate('report','/report/status','EXPORT','匯出充電樁狀態統計報表 csv')">{{$t('DownloadAllDataCsv')}}</el-button>
      </download-excel>-->
      <!--匯出CSV按鈕:若回傳listall若無資料則匯出按鈕不顯示-->
      </div>

      <div style="float: left;">
      <!--listAll Export to XLS-->
      <excel-xlsx
        :data="listAll" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xls'" :sheet-name="'sheetname'"
        v-if="listAll!=undefined&&listAll!=null&&listAll.length>0"
        >
        <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="logOperate('report','/report/status','EXPORT','匯出充電樁狀態統計報表 xls')">.xls</el-button>
      </excel-xlsx>
      <!--listAll Export to XLS-->
      </div>

      <div style="float: left;">
      <!--listAll Export to XLSX-->
      <excel-xlsx
        :data="listAll" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xlsx'" :sheet-name="'sheetname'"
        v-if="listAll!=undefined&&listAll!=null&&listAll.length>0"
        >
        <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block" @click="logOperate('report','/report/status','EXPORT','匯出充電樁狀態統計報表 xlsx')">.xlsx</el-button>
      </excel-xlsx>
      <!--listAll Export to XLSX-->
      </div>

      <div style="float: right;">
                                                               <!--替換:充電站選擇-->
        <el-select v-model="listQuery.stationId" :placeholder="$t('ChargingStationS')+'('+$t('All')+')'"
           clearable size="small" class="filter-item-mid" width="140px">
           <!--
          <el-option v-for="item in stationOption" :key="item.stationId"
                    :label="item.stationName + ' (id:' + item.stationId + ')'" :value="item.stationId" />-->
          <el-option v-for="item in stationOption" :key="item.stationId"
                    :label="item.stationName" :value="item.stationId" />
        </el-select>

        <el-select v-model="listQuery.chargePointId" :placeholder="$t('ChargingPointS')+'('+$t('All')+')'"
           clearable size="small" class="filter-item-mid" width="140px">
           <!--
          <el-option v-for="item in chargeOption"  :key="item.pointId"
                    :label="item.pointName + ' (id:' + item.pointId + ')'" :value="item.pointId" />-->
          <el-option v-for="item in chargeOption"  :key="item.pointId"
                    :label="item.pointName" :value="item.pointId" />
        </el-select>

        <el-select v-model="listQuery.chargeConnectorId" :placeholder="$t('ChargingConnectorS')+'('+$t('All')+')'"
           clearable size="small" class="filter-item-mid" width="140px">
           <!--
          <el-option v-for="item in connectorOption"  :key="item.connectorId"
                    :label="item.connectorName + ' (id:' + item.connectorId + ')'" :value="item.connectorId" />-->
          <el-option v-for="item in connectorOption"  :key="item.connectorId"
                    :label="item.connectorName" :value="item.connectorId" />
        </el-select>

        <!--
        <el-select v-model="listQuery.stationId" :placeholder="$t('ChargingStationS')" clearable size="small" class="filter-item-mid">
          <el-option v-for="item in $store.state.chargingStations" :key="item.id"
                    :label="item.name + ' (id' + item.id + ')'" :value="item.id" />
        </el-select>

        <el-select v-model="listQuery.chargePointId" :placeholder="$t('ChargingPointS')" clearable size="small" class="filter-item-mid">
          <el-option v-for="item in $store.state.chargingPoints" :key="item.id"
                    :label="item.name+ '(id:' + item.id + ')'" :value="item.id" />
        </el-select>
        -->

        <!--
        <el-input size="small" v-model="listQuery.staionId" placeholder="查詢" class="filter-item" clearable />      -->
        <!--
         <el-date-picker
            v-model="dateRange"
            size="small"
            class="filter-item"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="listQuery.startdate"
            :end-placeholder="listQuery.enddate">  
        </el-date-picker> -->
        <el-date-picker
            v-model="dateStart"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="listQuery.startdate">
        </el-date-picker>             
        <el-date-picker
            v-model="dateEnd"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="listQuery.enddate">
        </el-date-picker>            
     </div>
    </el-row>

    <!-- table -->

    <!--
    <el-table
      size="mini"
      stripe border height="500px"
      v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
      :header-cell-style="{ background: '#fcd581', color: '#333' }"
    > -->

    <el-table
      size="mini"
      v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
    >     
      <!--<el-table-column align="center" prop="no" min-width="30px"        label="No." /> -->
      <el-table-column align="center" prop="stationname"     min-width="60px"  :label="$t('ChargingStation')" sortable /><!--替換:充電站-->
      <el-table-column align="center" prop="chargepointname" min-width="60px"  :label="$t('ChargingPoint')" sortable /><!--替換:充電樁-->
      <el-table-column align="center" prop="connector_id"     min-width="50px"  :label="$t('ChargingConnector')" /><!--替換:充電槍-->
      <el-table-column align="center" prop="account_value"              :label="$t('Account')"           sortable/><!--替換:用戶-->
      <el-table-column align="center" prop="start_time"                 :label="$t('Start')+$t('Time')"  sortable/><!--替換:充電開始時間-->
      <el-table-column align="center" prop="meter_start"                :label="$t('StartDegree')"       sortable/><!--替換:充電開始度數-->
      <el-table-column align="center" prop="stop_time"                  :label="$t('End')+$t('Time')"    sortable/><!--替換:充電結束時間-->
      <el-table-column align="center" prop="meter_stop"                 :label="$t('EndDegree')"         sortable/><!--替換:充電結束度數-->
      <el-table-column align="center" prop="ocpp_transaction_stop_reason" :label="$t('EndReasonId')"       sortable/><!--替換:充電結束原因-->
      <el-table-column align="center" prop="update_time" :label="$t('Update')+$t('Date')" sortable :show-overflow-tooltip="true" min-width="80px">
      <template slot-scope="scope"> {{ parseDateTime(scope.row.update_time) }}</template>
      </el-table-column>
    </el-table>

    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';

/* 每隔10min刷新出jwt token*/
import {refreshToken, stopRefreshToken} from "@/utils/auth";


export default {
    name: 'Status',
    components: {
        Pagination
    },
    mixins: [mixins],
    data() {
        return {
            dialogWidth: 0,
            total: 0,
            stationOption    : undefined,
            chargeOption     : undefined,
            connectorOption  : undefined,
            list: [],
            listCsv: [],
            listAll: [],
            listLoading: false,
            listQuery: {
                //serviceId: this.$store.state.currentAccount.id,
                serviceId: this.$store.state.currentAccount.serviceInfo.id,
                stationId        : undefined,
                chargePointId    : undefined,
                chargeConnectorId: undefined,
                startdate: this.$moment().subtract(30, "days").format("YYYY-MM-DD"),//3天前
                enddate:   this.$moment().format("YYYY-MM-DD"),
                page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                size: 15,
                sort: "update_time,desc",
            },
            size           : undefined,
            paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
            dateRange: undefined,
            dateStart: undefined,
            dateEnd:   undefined,
            dialogSettingVisible: false,
            json_fields: {
              "Connector"   : "connector_id",
              "Account"     : "account_value",
              "StartTime"   : "start_time",
              "StartDegree" : "meter_start",
              "EndTime"     : "stop_time",
              "EndDegree"   : "meter_stop",
              "EndReasonId" : "ocpp_transaction_stop_reason",
              "UpdateDate"  : "update_time",
            },
            xlsxColumns : [
                {
                    label: this.$t('ChargingStation'),
                    field: "stationname",
                },
                {
                    label: this.$t('ChargingPoint'),
                    field: "chargepointname",
                },              
                {
                    label: this.$t('ChargingConnector'),
                    field: "connector_id",
                },
                {
                    label: this.$t('Account'),
                    field: "account_value",
                },
                {
                    label: this.$t('Start')+this.$t('Time'),
                    field: "start_time",
                },
                {
                    label: this.$t('StartDegree'),
                    field: "meter_start",
                },
                {
                    label: this.$t('End')+this.$t('Time'),
                    field: "stop_time",
                },
                {
                    label: this.$t('EndDegree'),
                    field: "meter_stop",
                },
                {
                    label: this.$t('EndReasonId'),
                    field: "ocpp_transaction_stop_reason",
                },
                {
                    label: this.$t('Update')+this.$t('Date'),
                    field: "update_time",
                },
                ],
            /* 匯出檔名 */
            filenameCsv:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_StatusReport" + ".xls",
            filenameAll:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_StatusReportAll" + ".xls",
            filenameAllXlsx: this.$moment().format("YYYYMMDD-HHmmss").toString() + "_StatusReportAll",
        };
    },
  created() {
    //定時刷新
    //refreshToken();

    this.dialogWidth = this.setDialogWidth()

    this.getStationByServiceId();

    this.resetPage(this.getList);
    //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求

  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  beforeDestroy() {
    console.log("Call stopRefreshToken");
    //stopRefreshToken();
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    /* 偵測若語系切換,則進行本組件切出去在切回來,以達本組件語系包含子組件(含對話視窗)都刷新 */
    '$i18n.locale': function(val) {
      console.log('Role組件重載前')
      this.$router.push( { path:'/refresh',query:{ fromPath:this.$route.fullPath } })
      console.log('Role組件重載完成')
      },
    /* ----------------------------------------------------------------------------- */
    'listQuery.stationId': function (val) {
      this.listQuery.page = 0;
      this.listQuery.chargePointId = null;
      this.resetPage(this.getList);
      this.listAll= [];
      this.chargeOption=null;
      /* 若充電站有被選擇,則才會找該充電站所屬的充電樁,沒被選擇則stationId為0 */
      if( this.listQuery.stationId != null && this.listQuery.stationId != undefined && this.listQuery.stationId > 0 ) {
        this.axios.get("/api/v1/report/pointidListByserviceIdandstationId?serviceId="+this.listQuery.serviceId+"&stationId="+val).then(
            res => {
            console.log("Request:pointidListByserviceIdandstationId [res.data]",res.data);
            this.chargeOption=res.data;
            }
            );
      }
     },
    'listQuery.chargePointId': function (val) {
      this.listQuery.page = 0;
      this.listQuery.chargeConnectorId = null;
      this.resetPage(this.getList);
      this.listAll= [];
      this.connectorOption=null;
      /* 若充電樁有被選擇,則才會找該充電樁所屬的充電槍,沒被選擇則chargePointId為"" */
      if( this.listQuery.chargePointId != null && this.listQuery.chargePointId != undefined && this.listQuery.chargePointId != "" ) {
        this.axios.get("/api/v1/report/connectoridListBy3Id?serviceId="+this.listQuery.serviceId+"&stationId="+this.listQuery.stationId+"&chargepointId="+val).then(
            res => {
            console.log("Request:connectoridListBy3Id [res.data]",res.data);
            this.connectorOption=res.data;
            }
            );
      }
     },
    'listQuery.chargeConnectorId': function (val) {
      this.listQuery.page = 0;
      this.resetPage(this.getList);
      this.listAll= [];
      //this.connectorOption=null;
     },
    /*
    dateRange: function(val) {
      if (val) {
        this.listQuery.startdate = this.dateRange[0]
        this.listQuery.enddate = this.dateRange[1]
      } else {
        this.listQuery.startdate = undefined
        this.listQuery.enddate = undefined
      }
      this.listQuery.page = 0;
      this.resetPage(this.getList)
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
      this.listAll= []
    },*/
    dateStart: function(val) {
      if (val) {
        this.listQuery.startdate = this.dateStart
      } else {
        this.listQuery.startdate = undefined
      }
        this.listAll= [];
        this.listQuery.page = 0;
        this.resetPage(this.getList);      
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    },   
    dateEnd: function(val) {
      if (val) {
        this.listQuery.enddate = this.dateEnd
      } else {
        this.listQuery.enddate = undefined
      }
        this.listAll= [];
        this.listQuery.page = 0;
        this.resetPage(this.getList);     
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    },      
  },

  methods: {
    exportToCSV() {
      this.logOperate('report','/report/status','EXPORT','匯出充電樁狀態統計報表 csv');
      //const csvContent = "data:text/csv;charset=Big5," + this.convertToCSV(this.listXls); //utf-8 
      const csvContent = this.convertToCSV(this.listCsv); //utf-8 
      //const csvContent = new Blob('\ufeff${csv}', {type: 'text/plain;charset=utf-8'})+ this.convertToCSV(data);
      //const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      //link.setAttribute("href", encodedUri);
      //link.setAttribute("download", "data.csv");
      //document.body.appendChild(link);
      //link.click();

      // "\ufeff"是为了解决CSV中文
      let blob = new Blob(["\ufeff" +csvContent],{type: 'text/csv;charset=utf-8;'});
      let url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', this.filenameAllXlsx);
      link.click();
    },
    convertToCSV(data) {
      const columnDelimiter = ",";
      const lineDelimiter = "\n";
      const keys = Object.keys(data[0]);

      let result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      data.forEach(item => {
        let row = "";
        keys.forEach(key => {
          row += item[key] + columnDelimiter;
        });
        result += row.slice(0, -1);
        result += lineDelimiter;
      });

      return result;
    },    
    getStationByServiceId() {
      console.log("GET:/api/v1/report/stationidListByserviceId?serviceId="+this.listQuery.serviceId);
      this.axios.get("/api/v1/report/stationidListByserviceId?serviceId="+this.listQuery.serviceId).then(
          res => {
          console.log("Response:/api/v1/report/stationidListByserviceId [res.data]",res.data);
          this.stationOption=res.data;
          }
         );
    },
    getList() {
      this.listAll= []
      var number = this.page
      var size   = this.size
      if( (this.listQuery.startdate==null) || (this.listQuery.enddate==null) ) {
        this.listQuery.startdate = this.$moment().subtract(90, "days").format("YYYY-MM-DD")//90天前;
        this.listQuery.enddate   = this.$moment().format("YYYY-MM-DD")
      }

      this.listLoading = true
      /*
      console.log('stationId:',this.listQuery.stationId);
      console.log('chargePointId:',this.listQuery.chargePointId);
      console.log('chargeConnectorId:',this.listQuery.chargeConnectorId); */

      console.table('GET:/api/v1/report/status [params]:',this.listQuery);
      this.axios.get('/api/v1/report/status', {params: this.listQuery}).then(res=> {
        console.log("Response:/api/v1/report/status [res.data]",res.data)
        this.list = res.data.content
        this.total = res.data.totalElements

        number = res.data.number
        size   = res.data.size
        
        var output = this.list.map( ( item , index ) => {
            item.no = index + number*size +1  //讓 this.list的Map加入一個key為no
            if( item.ocpp_transaction_stop_reason=="" || item.ocpp_transaction_stop_reason==null )
               item.ocpp_transaction_stop_reason = "NULL(" + item.status + ")"             
            if( item.ocpp_transaction_stop_reason=="EVDisconnected")
               item.ocpp_transaction_stop_reason = "充電完成後離線"  
            if( item.ocpp_transaction_stop_reason=="Remote")
               item.ocpp_transaction_stop_reason = "遠端結束"
            if( item.ocpp_transaction_stop_reason=="HardReset")
               item.ocpp_transaction_stop_reason = "硬體重啟"   
            if( item.ocpp_transaction_stop_reason=="SoftReset")
               item.ocpp_transaction_stop_reason = "軟體重啟"     
            if( item.ocpp_transaction_stop_reason=="Local")
               item.ocpp_transaction_stop_reason = "本地結束"                                                       
            return item
        })
        this.list = output;

        console.log("list =",this.list)

        this.listLoading = false
      })
    },
    exportReport(){
      this.size          = this.listQuery.size
      this.listQuery.page=0
      this.listQuery.size=100000

      this.axios.get('/api/v1/report/status', {params: this.listQuery}).then(res=> {
        console.log("Response:/api/v1/report/status [res.data]",res.data)
        this.listAll = res.data.content

        var output = this.listAll.map( ( item , index ) => {
            item.no = index   //讓 this.list的Map加入一個key為no
            if( item.ocpp_transaction_stop_reason=="" || item.ocpp_transaction_stop_reason==null )
               item.ocpp_transaction_stop_reason = "NULL(" + item.status + ")" 
            if( item.ocpp_transaction_stop_reason=="EVDisconnected")
               item.ocpp_transaction_stop_reason = "充電完成後離線"  
            if( item.ocpp_transaction_stop_reason=="Remote")
               item.ocpp_transaction_stop_reason = "遠端結束"
            if( item.ocpp_transaction_stop_reason=="HardReset")
               item.ocpp_transaction_stop_reason = "硬體重啟"   
            if( item.ocpp_transaction_stop_reason=="SoftReset")
               item.ocpp_transaction_stop_reason = "軟體重啟"     
            if( item.ocpp_transaction_stop_reason=="Local")
               item.ocpp_transaction_stop_reason = "本地結束"                              
            return item
        })
        this.listAll = output;

        this.listCsv = this.listAll.map(( item , index ) => {
          //item.no = index + number*size +1
          if(this.$i18n.locale == 'tw'){
            return {
              '充電站'  :   item.stationname,
              '充電樁'  :   item.chargepointname,
              '充電槍'  :   item.connector_id,
              '帳號'    :   item.account_value,
              '充電起始時間': item.start_time,
              '起始度數(Whn)':   item.meter_start,
              '充電結束時間': item.stop_time,
              '結束度數(Whn)':   item.meter_stop,
              '結束原因代號': item.ocpp_transaction_stop_reason,
              '更新日期':   item.update_time              
            }
          } else {
            return {
              'Charging Station':   item.stationname,
              'Charging Point':   item.chargepointname,
              'Charging Connector':   item.connector_id,
              'Account'    :   item.account_value,
              'Start Time': item.start_time,
              'Meter Start(Whn)':   item.meter_start,
              'End Time': item.stop_time,
              'Meter Stop(Whn)':   item.meter_stop,
              'Stop Reason': item.ocpp_transaction_stop_reason,
              'Update Date':   item.update_time
            }          
          }     
        })

      })
      this.listQuery.size=this.size
      this.filenameCsv =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_StatusReport" + ".xls"
      this.filenameAll =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_StatusReportAll" + ".xls"
      this.filenameAllXlsx= this.$moment().format("YYYYMMDD-HHmmss").toString() + "_StatusReportAll"
    },
    handleRowClick(row, column, event) {
    },
    /*
    objectSpanMethod({ row, column, rowIndex, columnIndex}) {
      if (columnIndex ===0) {
        if(rowIndex %2 === 0) {
          return { rowspan:2, colspan:1};
        } else {
          return { rowspan:0, colspan:0};
        }
      }
    }, */
  }
}

</script>
